/* global.css */

/* Ensure the body and HTML take full height */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
  }
  
  /* Wrapper for the entire app */
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Make it span the entire viewport height */
  }
  
  /* Ensure the main content pushes the footer down */
  .app-container > *:not(footer) {
    flex: 1; /* Push the footer to the bottom */
  }

  /* Light and Dark Mode Variables */
body[data-theme="light"] {
  --header-bg-color: #ffffff;
  --header-text-color: #000000;
  --menu-bg-color: #f0f0f0;
  --menu-text-color: #000000;
  --toggle-bg-color: #000000;
  --toggle-text-color: #ffffff;
}

body[data-theme="dark"] {
  --header-bg-color: #000000;
  --header-text-color: #ffffff;
  --menu-bg-color: #1a1a1a;
  --menu-text-color: #ffffff;
  --toggle-bg-color: #ffffff;
  --toggle-text-color: #000000;
}
