/* General Styles */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #000000; /* Darker background for a sleek look */
    color: #ffffff; /* Contrast text */
  }
  
  
  .gallery-wrapper {
    margin-top: 80px;
    padding: 40px 20px;
    text-align: center;
  }
  /* Open for Work Section */
.open-for-work {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}

.green-dot {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #008000; /* Solid green dot */
  border-radius: 50%;
}

/* Ping effect */
.green-dot::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 128, 0, 0.3); /* Transparent green for ping effect */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: ping 1.5s infinite;
}

/* Keyframes for the ping effect */
@keyframes ping {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}
  
  .gallery-title {
    font-size: 48px;
    margin-bottom: 40px;
    color: #e0e0e0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    position: relative;
    display: inline-block;
  }
  
  .gallery-title::after {
    content: '';
    display: block;
    margin: 10px auto 0;
    width: 80px;
    height: 4px;
    background: #e63946; /* Accent underline */
  }
  
  /* Gallery Grid */
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    padding: 0 20px;
  }
  
  /* Gallery Item */
  .item {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
  }
  
  .item:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  }
  
  /* Gallery Image */
  .gallery-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .item:hover .gallery-image {
    transform: scale(1.1); /* Subtle zoom effect */
  }
  
  /* Overlay Effect */
  .item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  .item:hover::before {
    opacity: 1; /* Reveal overlay */
  }
  
  /* Caption */
  .gallery-item-title {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    z-index: 2;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.4s ease, transform 0.4s ease;
  }
  
  .item:hover .gallery-item-title {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .gallery-title {
      font-size: 32px;
    }
  
    .gallery {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  
    .gallery-item-title {
      font-size: 16px;
    }
  }
  