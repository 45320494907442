/* ------------------------- Progress balkje -------------------------------------------------- */

.progressContainer {
	position: fixed;
	top: 50%;
	right: 40px;
	z-index: 5;
	height: 100px;
	width: 2px;
	background: rgba(152, 152, 158, 0.3);
	overflow: hidden;
	transform: translate(0, -50%);
	
}

.progressFill {
	width: 2px;
	background: #daa520;
	height: 0%;
}

@media screen and (max-width: 760px){
	.progressContainer {
		right: 15px;	
	}
}

@media screen and (max-width: 600px) {
    .nav-icon {
        width: 40px;
        height: 40px;
    }

    .top-bar-home p {
        font-size: 12px;
    }
}
