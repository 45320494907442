@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --transition: 0.3s ease;
  /* Light mode colors */
  --bg-light: #ffffff;
  --text-light: #000000;
  /* Dark mode colors */
  --bg-dark: #000000;
  --text-dark: #ffffff;
  --font-sans: "Montserrat", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-sans);
}



/* The hero-container will now handle dark and light mode states */
.hero-container {
  display: flex;
  flex-direction: column;
  transition: background-color var(--transition), color var(--transition);
}

.hero-container.light {
  background-color: var(--bg-light);
  color: var(--text-light);
}

.hero-container.dark {
  background-color: var(--bg-dark);
  color: var(--text-dark);
}

/* Main Hero Section */
.hero-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  box-sizing: border-box;
}

/* Greeting (name) */
.hero-greeting {
  font-size: 5rem;
  margin: 0.5rem 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  transition: background-color var(--transition), color var(--transition);
  opacity: 0.9;
}

/* Description under the name */
.hero-description {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  transition: background-color var(--transition), color var(--transition);
  margin-bottom: 1rem;
}


/* CTA button */
.hero-cta-button {
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color var(--transition), color var(--transition);
}



/* Differentiate button for dark/light */
.hero-container.light .hero-cta-button {
  background-color: #000000;
  color: #ffffff;
}

.hero-container.light .hero-cta-button:hover {
  background-color: #333333;
  color: #ffffff;
}

.hero-container.dark .hero-cta-button {
  background-color: #ffffff;
  color: #000000;
}

.hero-container.dark .hero-cta-button:hover {
  background-color: #dddddd;
}

/* Responsive */
@media (max-width: 768px) {
  .hero-greeting {
    font-size: 3rem;
  }

  .hero-description {
    font-size: 1.25rem;
  }

  .hero-cta-button {
    margin-top: 1rem;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
  }
}

@media (max-width: 470px) {
  .hero-greeting {
    font-size: 1.5rem;
    
  }

  .hero-description {
    font-size: 1rem;
  }

  .hero-cta-button {
    margin-top: 0.75rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}
