:root {
  --transition: 0.3s ease;
}

.about-page {
  padding-top: 80px;
}

/* Reusable Title Style */
.section-title {
  font-size: 2em;
  margin-bottom: 10px;
}


.section-title .color {
  color: #daa520; /* Match the gold color for highlighted text */
}
/* Open for Work Section */
.open-for-work {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}

.green-dot {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #008000; /* Solid green dot */
  border-radius: 50%;
}

/* Ping effect */
.green-dot::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 128, 0, 0.3); /* Transparent green for ping effect */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: ping 1.5s infinite;
}


.about-page.light {
  background-color: #ffffff;
  color: #000;
  transition: background-color var(--transition), color var(--transition);
}

.about-page.dark {
  background-color: #000;
  color: #fff;
  transition: background-color var(--transition), color var(--transition);
}

/* Greeting Animation */
#time-greeting {
    display: center;
  font-size: 1.0em; /* Match the rest of the text size */
  animation: greetingSlideIn 1.0s ease-out forwards;
  opacity: 0;
}

@keyframes greetingSlideIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  width: 80%;
  max-width: 800px;
}

.left-column h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.left-column p {
  font-size: 1.2em;
  line-height: 1.8;
}

h1 .color {
  color: #daa520;
}

/* Centered Timeline Section */

.timeline-title {
  font-size: 2em;
  color: #ffffff;
  text-align: center;
  font-family: Montserrat, sans-serif;
  max-width: 850px;
  margin-bottom: 20px;
}

.color {
  color: #daa520;
}


.timeline-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  align-items: center;
}

.timeline-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000;
  padding: 10px 15px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  width: 80%;
  max-width: 800px;
  transition: background-color var(--transition);
}

.timeline-block:hover {
  background: #222;
}

.timeline-logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.timeline-content {
  flex-grow: 1;
  margin-left: 15px;
}

.timeline-content h4,
.timeline-content p {
  margin: 0;
}

.timeline-date {
  font-size: 0.9em;
  color: #aaa;
  margin-right: 40px; /* Add space between plus icon and date */
  text-align: right;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
/* Plus Button Styling */
.plus-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.5em; /* Adjust font size for better visibility */
  font-weight: bold;
  color: #fff;
  background-color: #daa520;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform var(--transition), background-color var(--transition);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow for a 3D effect */
}

.plus-button:hover {
  background-color: #333;
  transform: scale(1.1); /* Slightly enlarge on hover for interactivity */
}

.plus-button.open {
  transform: rotate(45deg); /* Rotate for a smooth transition to 'X' */
  background-color: #555; /* Change background color when open */
  color: #fff;
}

.plus-button.open:hover {
  background-color: #000;
  transform: rotate(45deg) scale(1.1);
}
xx
.left-column {
  flex: 1;
}

.right-column {
  flex: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portrait-image {
  width: 250px; /* Fixed circular size */
  height: 250px; /* Fixed circular size */
  margin-top: 50px;
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the circle without distortion */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow for a subtle 3D effect */
}




.timeline-details {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  max-width: 800px;
  transition: all var(--transition);
}

.timeline-details ul {
  margin: 0;
  padding: 0 15px;
}

.timeline-details ul li {
  margin-bottom: 5px;
  color: #fff;
}

/* Light mode adjustments */
.about-page.light .timeline-block {
  background: #f9f9f9;
}

.about-page.light .timeline-block:hover {
  background: #eaeaea;
}

.about-page.light .timeline-details {
  background: #ffffff;
}

.about-page.light .timeline-content h4,
.about-page.light .timeline-content p,
.about-page.light .timeline-title,
.about-page.light .timeline-date {
  color: #000;
}
.about-page.about-page.dark .timeline-title {
  color: #fff;
}
.about-page.light .plus-button {
  background-color: #000;
  color: #fff;
}
.about-page.dark .plus-button {
  background-color: #ffffff;
  color: #000000;
}

.about-page.light .plus-button:hover {
  background-color: #555;
}

.about-page.light .timeline-details ul li {
  color: #333;
}

@media (max-width: 480px) {
  /* Timeline Block for Mobile View */
  .timeline-block {
    display: flex;
    flex-direction: row; /* Keep the row layout */
    align-items: flex-start; /* Align items at the top */
    padding: 5px;

    gap: 10px; /* Space between logo and content */
  }

  /* Timeline Logo */
  .timeline-logo img {
    width: 40px;
    height: 40px;
  }

  /* Timeline Content */
  .timeline-content {
    display: flex;
    flex-direction: column; /* Stack title and date vertically */
    justify-content: flex-start;
    align-items: flex-start; /* Align items to the left */
    flex-grow: 1; /* Allow content to take available space */
    padding-right: 5px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 5px;
    padding-bottom: 5px;
    position: relative; /* Enable precise positioning */
  }
  .timeline-title {
    font-size: 2em;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    width: 50%;
    
    max-width: 1120px;
    margin-bottom: 20px;
  }
  
  .color {
    color: #daa520;
  }

  .timeline-content h4 {
    font-size: 1em; /* Adjust font size for smaller screens */
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    text-align: left;
    color: inherit;
  }
  .timeline-content p {
    font-size: 0.6rem;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
  }

  .timeline-date {
    font-size: 0.8rem; /* Slightly smaller font size for the date */
    color: #aaa;
    visibility: hidden;
    position: absolute; /* Position the date */
    bottom: 0; /* Align at the bottom of the content container */
    left: 0; /* Align to the left */
  }

  /* Plus Button */
  .plus-button {
    width: 35px;
    height: 35px;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    margin-left: auto; /* Push the button to the right */
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  .plus-button:hover {
    background-color: #555;
  }
  .content-wrapper {
    flex-direction: column; /* Stack the columns vertically */
    align-items: center; /* Center align items in mobile view */
  }

  .right-column {
    margin-top: 20px; /* Add spacing between left column and image */
    flex: none; /* Reset flex property */
    width: 100%; /* Ensure full-width on smaller screens */
    text-align: center; /* Center-align the image */
  }

  .portrait-image {
    margin-top: 0; /* Remove additional margin */
  }
  /* Timeline Details (Modal) */
  .timeline-details {
    padding: 10px;
    font-size: 1rem;
    text-align:justify;
    background: rgba(255, 255, 255, 0.1); /* Slightly transparent */
    width: 80%;
    border-radius: 10px;
  }
}

@media (max-width: 480px) {
  .plus-button {
    width: 35px;
    height: 35px;
    font-size: 1.2em;
  }
}
