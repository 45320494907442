/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    background: #000000;
    color: #fff;
    margin: 0;
    padding: 0;
  }
  
  /* Resume Page */
  .resume-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Header Section */
  .header-section {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .header-section h1 {
    margin-top: 50px;
    font-size: 2.5rem;
    color: #daa520;
    margin-bottom: 10px;
  }
  
  .header-section p {
    font-size: 1rem;
    color: #ccc;
  }
  
  .header-section a {
    color: #fff;
    text-decoration: underline;
  }
  
  .header-section a:hover {
    color: #daa520;
  }
  
  .download-button-container {
    margin-top: 20px;
  }
  
  .download-button {
    background-color: #252525;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;
    transition: all 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #fff;
    color: #daa520;
    transform: scale(1.05);
  }
  
  /* Section Titles */
  .section h2 {
    font-size: 2rem;
    color: #daa520;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Skills Section */
  .skills-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .skills-category h3 {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .skills-category p {
    font-size: 1rem;
    color: #ccc;
  }
  
  .skill {
    font-weight: bold;
    color: #daa520;
  }
  
  /* Experience Section */
  .experience-item {
    margin-bottom: 20px;
  }
  
  .experience-item h3 {
    font-size: 1.5rem;
    color: #fff;
  }
  
  .experience-item p {
    font-size: 1rem;
    color: #ccc;
  }
  
  /* Education Section */
  .education-item {
    margin-bottom: 20px;
  }
  
  .education-item h3 {
    font-size: 1.5rem;
    color: #fff;
  }
  
  .education-item p {
    font-size: 1rem;
    color: #ccc;
  }
  
  /* Projects Section */
  .project-item {
    margin-bottom: 20px;
  }
  
  .project-item h3 {
    font-size: 1.5rem;
    color: #fff;
  }
  
  .project-item p {
    font-size: 1rem;
    color: #ccc;
  }
  
  /* Achievements Section */
  #achievements p {
    font-size: 1rem;
    color: #ccc;
    margin-top: 10px;
    text-align: center;
  }
    
/* Responsive Design */
@media (max-width: 768px) {
    .header-section {
      padding-top: 5px; /* Reduced padding */
      padding-bottom: 10px; /* Add some spacing below the header */
    }
  
    .header-section h1 {
      font-size: 1.8rem; /* Slightly smaller for mobile */
      margin-top: 100px;
      line-height: 2.2rem; /* Adjust line height for better readability */
    }
  
    .header-section p {
      font-size: 0.9rem; /* Smaller font for email and contact info */
      margin-top: 8px; /* Add a small gap between lines */
    }
  
    .download-button-container {
      margin-top: 10px;
    }
  
    .download-button {
      font-size: 0.9rem; /* Smaller button text */
      padding: 8px 12px; /* Adjust button padding */
    }
  
    .section {
      padding: 15px; /* Less padding for sections */
    }
  
    .section h2 {
      font-size: 1.4rem; /* Reduce section heading size */
      margin-bottom: 10px; /* Less space below headings */
    }
  
    .skills-container {
      grid-template-columns: 1fr; /* Single column for mobile */
      gap: 10px; /* Reduced gap between skill categories */
    }
  
    .skills-category h3 {
      font-size: 1.1rem; /* Smaller subheadings */
      margin-bottom: 5px;
    }
  
    .skills-category p {
      font-size: 0.9rem; /* Smaller font for skills */
    }
  
    .experience-item h3,
    .education-item h3,
    .project-item h3 {
      font-size: 1rem; /* Smaller font for titles */
      margin-bottom: 5px; /* Less space below titles */
    }
  
    .experience-item p,
    .education-item p,
    .project-item p {
      font-size: 0.9rem; /* Smaller body text */
      line-height: 1.4; /* Adjust line spacing */
    }
  
    .footer {
      padding: 15px; /* Reduce footer padding */
    }
  
    .footer a {
      font-size: 0.8rem; /* Smaller footer links */
    }
  
    .footer-name {
      font-size: 0.8rem; /* Reduce footer name size */
      margin-top: 8px; /* Smaller gap above name */
    }
  }
  
  /* For Extra Small Devices (Phones) */
  @media (max-width: 480px) {
    .header-section {
      padding-top: 10px; /* Further reduce padding */
    }
  
    .header-section h1 {
      font-size: 1.6rem; /* Smaller title for very small screens */
      line-height: 2rem;
    }
  
    .header-section p {
      font-size: 0.8rem; /* Reduce font size for contact info */
    }
  
    .download-button {
      font-size: 0.8rem; /* Smaller button text */
      padding: 6px 10px; /* Adjust button padding */
    }
  
    .section h2 {
      font-size: 1.2rem; /* Smaller section headings */
    }
  
    .skills-category h3 {
      font-size: 1rem; /* Adjust skill subheading size */
    }
  
    .skills-category p,
    .experience-item p,
    .education-item p,
    .project-item p {
      font-size: 0.8rem; /* Reduce general text size further */
    }
  
    .footer {
      padding: 10px; /* Compact footer spacing */
    }
  
    .footer a {
      font-size: 0.7rem; /* Smaller font for footer links */
    }
  
    .footer-name {
      font-size: 0.7rem; /* Adjust name size for extra small devices */
    }
  }
  