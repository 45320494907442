@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
:root {
  --transition: 0.3s ease;
}
/* Header container */
.header {
  width: 70%; /* Keeps header width as specified */
  margin: 0 auto; /* Centers the header horizontally */
  position: sticky; /* Makes the header sticky */
  background-color: var(--header-bg-color);
  margin-top: 20px;
  position: sticky; /* Makes the header sticky */
  top: 0; /* Sticks the header to the top of the viewport */
  z-index: 1000; /* Ensures the header is above other elements */
  color: var(--header-text-color);
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Centers vertically */
  justify-content: center; /* Centers horizontally */
  transition: background-color var(--transition), color var(--transition);
  height: 100px; /* Ensures enough space for vertical alignment */
}

/* .header-inner with 3 sections: left, center, right */
.header-inner {
  display: grid;
  grid-template-columns: auto 1fr auto; /* left, center, right */
  align-items: center; /* Vertically align items */
  justify-content: center; /* Horizontally align content */
  width: 100%;
  padding: 0 20px;
}


.logo-text {
  font-size: 20px;
  color: var(--header-text-color);
  text-decoration: none;
  letter-spacing: 2px;
  font-family: 'Montserrat', sans-serif;
  transition: color var(--transition);
}

.logo-text:hover {
  color: var(--accent-color);
}

/* ---------- CENTER: OPEN FOR WORK ---------- */
/* ---------- CENTER: OPEN FOR WORK ---------- */
.header-center {
  display: flex;
  justify-content: center; /* Center horizontally */
}

.open-for-work {
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* Allows items to wrap to the next line in mobile view */
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  gap: 10px; /* Decrease the gap between the green dot and text */
}

/* Green dot */
.green-dot {
  width: 10px;
  height: 10px;
  background: #00cc00; /* Green dot */
  border-radius: 50%;
  margin-right: -10px; /* Directly control the spacing between dot and text */
}

/* Logo Styling */
.logo img {
  height: 40px;
}

/* ---------- RIGHT: Desktop Nav + ISO + Menu Button ---------- */
.header-right {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between nav, iso, and button */
}

/* Desktop nav in the right section */
.nav-desktop ul {
  list-style: none;
  display: flex;
  gap: 30px;
  margin: 0;
  padding: 0;
}

.nav-desktop ul li a {
  color: var(--header-text-color);
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color var(--transition);
}

.nav-desktop ul li a:hover {
  color: var(--accent-color);
}

/* ISO toggle (corner-borders) */
.iso-toggle {
  background: none;
  border: none;
  color: var(--header-text-color);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  padding: 0 15px;
  transition: color var(--transition);
  font-family: 'Montserrat', sans-serif;
}

.iso-toggle:hover {
  color: var(--accent-color);
}

.iso-toggle::before,
.iso-toggle::after {
  content: '';
  position: absolute;
  border: 1px solid var(--accent-color);
}

.iso-toggle::before {
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-bottom: none;
  border-right: none;
}

.iso-toggle::after {
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-top: none;
  border-left: none;
}

/* Optional little animation when toggled */
.iso-toggle.animated {
  animation: isoFlash 0.5s ease-in-out;
}
@keyframes isoFlash {
  50% { transform: scale(1.2); }
}

/* Menu button (corner-borders), but hidden on desktop */
.menu-toggle {
  background: none;
  border: none;
  color: var(--header-text-color);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  padding: 0 15px;
  transition: color var(--transition);
  font-family: 'Montserrat', sans-serif;
}

.menu-toggle:hover {
  color: var(--accent-color);
}

.menu-toggle::before,
.menu-toggle::after {
  content: '';
  position: absolute;
  border: 1px solid var(--accent-color);
}

.menu-toggle::before {
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-bottom: none;
  border-right: none;
}

.menu-toggle::after {
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-top: none;
  border-left: none;
}

/* ---------- MOBILE NAV OVERLAY ---------- */
.nav-mobile {
  position: absolute;
  top: 60px;
  right: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background: rgba(0, 0, 0, 0.282);
  backdrop-filter: blur(10px);
  padding: 20px;
  box-sizing: border-box;
  z-index: 10;
  animation: fadeIn 0.3s ease forwards;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  transition: background-color var(--transition), color var(--transition);
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

body.light-mode .nav-mobile {
  background: rgba(255, 255, 255, 0.234);
  color: var(--text-color-light);
}

.nav-section h2 {
  color: var(--accent-color);
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.directory-list,
.socials-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.directory-list li,
.socials-list li {
  margin: 15px 0;
}

.directory-list li a {
  text-decoration: none;
  color: var(--text-color-dark);
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  transition: color var(--transition);
}

.socials-list li a {
  text-decoration: none;
  color: var(--text-color-dark);
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  transition: color var(--transition);
}

body.light-mode .directory-list li a,
body.light-mode .socials-list li a {
  color: var(--text-color-light);
}

.directory-list li a:hover,
.socials-list li a:hover {
  color: var(--accent-color);
}

.menu-index {
  color: var(--text-color-dark);
  font-size: 14px;
  margin-right: 10px;
  opacity: 0.7;
  letter-spacing: 1px;
}

body.light-mode .menu-index {
  color: var(--text-color-light);
}

.contact-section p {
  color: var(--text-color-dark);
  font-size: 20px;
  margin: 8px 0;
  font-family: 'Montserrat', sans-serif;
}

body.light-mode .contact-section p {
  color: var(--text-color-light);
}

.contact-label {
  color: var(--accent-color);
  margin-right: 5px;
}

.contact-email {
  color: var(--text-color-dark);
  text-decoration: none;
}

body.light-mode .contact-email {
  color: var(--text-color-light);
}

.contact-email:hover {
  text-decoration: underline;
}

.location {
  color: var(--text-color-dark);
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  opacity: 0.7;
}

body.light-mode .location {
  color: var(--text-color-light);
}

/* ---------- RESPONSIVE BREAKPOINTS ---------- */
/* Desktop: show nav-desktop, hide menu button */
@media (min-width: 768px) {
  .menu-toggle {
    display: none;
  }
}

/* Mobile: hide the .nav-desktop, show the .menu-toggle */
@media (max-width: 767px) {
  .nav-desktop {
    display: none;
  }

  .logo-text {
    font-size: 20px;
  }

  .header {
   margin-top: 30px;
    width: 100%; /* Use full width for mobile */
  }

  .iso-toggle {
    padding: 0 10px;
    font-size: 14px;
    font-weight:500;
    margin-right: -10px;
  }

  .menu-toggle {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
  }
}
/* For very small screens (e.g., phones < 480px wide) */
@media (max-width: 480px) {
  .logo-text {
    font-size: 16px; /* Reduce logo text size */
    letter-spacing: 1px; /* Adjust spacing for smaller size */
  }

  .header {
    height: 80px; /* Reduce header height */
    margin-top: 10px; /* Decrease margin for compact spacing */
  }

  .header-inner {
    padding: 0 10px; /* Reduce padding for small screens */
  }

  .open-for-work {
    font-size: 10px; /* Reduce text size */
    gap: 5px; /* Decrease gap between elements */
  }

  .green-dot {
    width: 8px; /* Smaller dot */
    height: 8px; /* Smaller dot */
    margin-right: -8px; /* Adjust spacing for smaller size */
  }

  .nav-desktop ul li a {
    font-size: 14px; /* Smaller nav text */
  }

  .iso-toggle {
    font-size: 12px; /* Reduce ISO toggle size */
    padding: 0 8px; /* Adjust padding */
  }

  .menu-toggle {
    font-size: 12px; /* Reduce menu toggle size */
    padding: 0 8px; /* Adjust padding */
  }

  .nav-mobile {
    padding: 15px; /* Reduce padding for mobile nav overlay */
    gap: 20px; /* Adjust gap between nav items */
  }

  .directory-list li a,
  .socials-list li a {
    font-size: 16px; /* Reduce link font size */
    letter-spacing: 0.5px; /* Slightly decrease spacing */
  }

  .menu-index {
    font-size: 12px; /* Reduce index text size */
  }

  .contact-section p {
    font-size: 16px; /* Reduce contact section font size */
  }

  .location {
    font-size: 10px; /* Smaller text for location */
  }
}
