.footer {
  padding: 20px;
  display: flex;
  font-size: 1em;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.footer.light {
  background-color: #ffffff;
  color: #000000;
}

.footer.dark {
  background-color: #000000;
  color: #ffffff;
}

.footer-container {
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.footer-social {
  display: flex;
  gap: 15px;
  white-space: nowrap;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer a:hover {
  color: #daa520;
}

.footer-name {
  text-align: right;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer-container {
    flex-direction: column;
    gap: 15px;

  }

  .footer-name {
    text-align: center;
  }
}
