/* BlogPage.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Theme variables */
:root {
  --text-color-light: #000;
  --background-color-light: #fff;
  --text-color-dark: #fff;
  --background-color-dark: #000;
  --accent-color: #808080;
  --transition: 0.3s ease;
}

/* Dark / light mode on body */
body.dark-mode {
  background: var(--background-color-dark);
  color: var(--text-color-dark);
}
body.light-mode {
  background: var(--background-color-light);
  color: var(--text-color-light);
}

/* Overall page styling */
.blog-page {
  width: 70%;
  min-height: 100vh;
  margin: 0 auto; /* Center the container on larger screens */
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  transition: background-color var(--transition), color var(--transition);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center content vertically and horizontally */
}

/* Title and subtitle */
.blog-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.blog-subtitle {
  margin-bottom: 2rem;
  font-weight: 300;
}

/* Blog posts container */
.blog-posts {
  margin-bottom: 3rem;
}

/* Individual post item */
.post-item {
  display: flex;          /* Flexbox layout for date and title */
  justify-content: center; /* Center items horizontally */
  align-items: center;    /* Align items vertically */
  text-align: center;
  gap: 1rem;              /* Space between date and title */
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #333;
}

.post-date {
  font-size: 0.95rem;
  color: #888;
}

.post-title {
  font-size: 1rem;
  font-weight: bold;
}

/* Newsletter section */
.newsletter {
  border-top: 1px solid #333;
  padding-top: 2rem;
}

.newsletter-form {
  margin-bottom: 1rem;
}

.newsletter-input {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #999;
  background-color: #222;
  color: #fff;
}

.newsletter-button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #444;
  color: #fff;
  cursor: pointer;
}

/* --------------------------- */
/*         MOBILE VIEW         */
/* --------------------------- */
@media (max-width: 600px) {
  /* Slightly reduce container width and padding on mobile */
  .blog-page {
    width: 90%;
    padding: 1rem;
  }

  /* Adjust font sizes */
  .blog-title {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }

  .blog-subtitle {
    margin-bottom: 1.5rem;
    font-weight: 300;
  }

  /* Flex remains for post-item to keep date and title on the same line */
  .post-item {
    gap: 0.5rem;
  }

  .post-date,
  .post-title {
    font-size: 0.9rem;
  }

  .newsletter {
    padding-top: 1rem;
  }
}
