@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Base container styles */
.blog-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
  }
  
  /* Back link */
  .blog-back-link {
    margin-bottom: 20px;
    text-align: left;
  }
  .blog-back-link a {
    color: #007bff;
    text-decoration: none;
    font-size: 0.9rem;
  }
  .blog-back-link a:hover {
    text-decoration: underline;
  }
  
  /* Article layout */
  .blog-article {
    text-align: left;
  }
  
  /* Two-column header (optional if you want a left/right format) */
  .blog-header {
    display: flex;
    flex-direction: column; /* Single-column by default; remove if you want a true two-column */
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  /* Blog title & meta info */
  .blog-title {
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center; /* Center the title horizontally */
  }
  
  .blog-meta {
    font-size: 0.9rem;
    text-align: center; 
    margin-bottom: 20px;
  }
 /* Updated Author Section Styles */
.blog-author {
  display: flex; /* Align items in a row */
  align-items: center; /* Vertically center-align the items */
  gap: 15px; /* Space between the image and text */
  margin: 20px 0;
}

.blog-author img {
  width: 50px;
  height: 50px;
  border-radius: 50%; /* Ensure the image is circular */
  object-fit: cover; /* Prevent distortion */
}

.author-details {
  display: flex; /* Align name and date in a row */
  flex-direction: column; /* Align text vertically in the desktop view */
  gap: 3px; /* Space between the name and date */
}

.author-name {
  font-size: 1rem;
  font-weight: bold;
}

.author-date {
  font-size: 0.9rem;

}

/* Mobile View Adjustments */
@media (max-width: 720px) {
  .blog-author {
    display: flex; /* Align items in a row */
  align-items: center; /* Vertically center-align the items */
  gap: 15px; /* Space between the image and text */
  margin: 20px 0;
  }

  .blog-author img {
    width: 40px; /* Adjust image size for mobile */
    height: 40px;
  }

  .author-name {
    font-size: 0.95rem; /* Slightly smaller font for name */
  }

  .author-date {
    font-size: 0.85rem; /* Slightly smaller font for date */
  }
}

  /* Body text */
  .blog-article p {
    margin-bottom: 20px;
  }
  
  .blog-article h2 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.5rem;
    
  }
  
  .blog-article ul,
  .blog-article ol {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .blog-article ul li,
  .blog-article ol li {
    margin-bottom: 10px;
  }
  
  /* Make code blocks stand out */
  .blog-article pre {
    background-color: #1e1e1e; /* Dark background for contrast */
    color: #ccc;              /* Lighter text */
    padding: 1rem;
    border-radius: 6px;
    overflow-x: auto;         /* Horizontal scroll for long code */
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;    /* Space after code blocks */
  }
  
  /* Inline code snippet */
  .blog-article code {
    font-family: 'Montserrat', sans-serif;
    background-color: #252526;
    color: #79c0ff;           /* Blue-ish text for visibility */
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 0.9rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    /* Container */
    .blog-container {
      padding: 15px;
      max-width: 100%; 
    }
    
    /* Title & meta spacing */
    .blog-title {
      font-size: 1.8rem;
    }
    .blog-meta {
      font-size: 0.85rem;
      margin-bottom: 15px;
    }
  
    /* Author section on mobile */
    .blog-author {
      flex-direction: column;
      gap: 10px;
    }
    .blog-author img {
      width: 40px;
      height: 40px;
    }
  
    /* Headings & paragraphs */
    .blog-article h2 {
      font-size: 1.3rem;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .blog-article p {
      font-size: 0.95rem;
      margin-bottom: 15px;
    }
    .blog-article ul li,
    .blog-article ol li {
      margin-bottom: 8px;
    }
  
    /* Code blocks on mobile */
    .blog-article pre {
      font-size: 0.85rem;
      padding: 0.75rem;
      margin-bottom: 1rem;
    }
    .blog-article code {
      font-size: 0.85rem;
    }
  }
  