
/* General Styles */
.project-page {
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
}

.project-page.light-mode {
  background-color: #ffffff;
  color: #000000;
}

.project-page.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

a:hover {
  text-decoration: underline;
}

/* Header Section */
header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

header p {
  margin: 0;
  color: var(--text-color-secondary);
}

/* Intro Paragraph */
.project-page p {
  line-height: 1.6;
  margin: 2rem 0;
  color: var(--text-color);
}

/* Author Section */
.project-author {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.project-author img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 1rem;
}

.project-author a {
  text-decoration: none;
  font-weight: bold;
  color: var(--text-color);
}

/* Dataset Information Section */
h2 {
  margin-top: 3rem;
  color: var(--text-color);
}

ul {
  line-height: 1.6;
  margin-left: 1.5rem;
  color: var(--text-color);
}

/* Visualizations */
figure {
  margin: 2rem 0;
}

figure img {
  width: 100%;
  margin-bottom: 0.5rem;
}

figure figcaption {
  font-size: 0.9rem;
  color: var(--text-color-secondary);
}

/* Machine Learning Models Section */
ul {
  line-height: 1.6;
  margin-left: 1.5rem;
  color: var(--text-color);
}

/* Conclusion Section */

.project-page p:last-of-type {
  margin-bottom: 3rem;
}

